/*******************************************************************************
  설  명 : 주문하기
  생성일 : 2020-09-10
  생성자 : 송영석
*******************************************************************************/
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import 'moment/locale/ko';

import { AuthService } from '@app/service/auth.service';
import { SMemberService } from '@app/service/member.service';
import { SProductService } from '@app/service/product.service';
import { SOrderService } from '@app/service/order.service';
import { UtilService } from '@app/service/util.service';
import { SCartService } from '@app/service/cart.service';
import { SEventService } from '@service/event.service';

import { ProductCouponComponent } from '@page/cart/product-coupon/product-coupon.component';
import { InstallmentGuideComponent } from '@page/order/installment-guide/installment-guide.component';
import { MypageDestinationComponent } from '@page/mypage/mypage-destination/mypage-destination.component';

declare let INIStdPay : any;

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '760',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const options1: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '520',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const options2: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: '1000',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @ViewChild('kginicis', { read: ElementRef }) kginicis: ElementRef;
  @ViewChild('info_receive_address_zipcode', { static: false }) infoReceiveAddressZipcode: ElementRef;
  @ViewChild('info_receive_address_sub', { static: false }) infoReceiveAddressSub: ElementRef;
  @ViewChild('info_order_hp2', {static: false}) infoOrderHp2: ElementRef;
  @ViewChild('info_order_hp3', {static: false}) infoOrderHp3: ElementRef;
  @ViewChild('info_receive_phone2', {static: false}) infoReceivePhone2: ElementRef;
  @ViewChild('info_receive_phone3', {static: false}) infoReceivePhone3: ElementRef;

  public orderForm: FormGroup;
  public formErrors: any = {};

  public isLogIn$: Observable<boolean>;
  public isLogIn: boolean;

  public info_product: any = []; // 주문상품 정보
  public info_coupon_list: any = []; // 사용가능한 쿠폰 정보
  public accountList: any = [];
  public couponList: any = [];  
  public customerList: any = [];
  public quotabaseList: any = [0, 1, 2, 3, 4, 5, 6, 11, 12];

  public info_mileage: any = 0; // 마일리지
  public gopaymethod: any = 'Card';   // 결제방법
  public nointerest: any = '';  // 상점부담 무이자할부 옵션 [카드코드-할부개월:할부개월,카드코드-할부개월:할부개월]
  public quotabase: any = '0:1:2:3:4:5:6:11:12';  // 할부개월수 노출옵션 [할부개월:할부개월]
  public serverName: any = '';
  public isMobile: boolean;
  public isTablet: boolean;
  public isDesktop: boolean;
  public emailReadonly: boolean = false;
  public emailAddress = '';
  public isSubmit: boolean = false;
  public isCoupon: boolean = false;

  public IniPayStd: any = {
    mid: '',
    oid: '',
    timestamp: '',
    price: '',
    mKey: '',
    signature: '',
    memNo: '',
    tmpSeq: ''
  };

  // 회원 정보
  public memberInfo = {
    address: '',
    address_detail: '',
    address_state: '',
    birth_sec: '',
    birthdate: '',
    email: '',
    email_yn: '',
    grade: '',
    hp: '',
    id: '',
    kakao_yn: '',
    mem_no: '',
    name: '',
    recipient_hp: null,
    recipient_name: null,
    sms_yn: '',
    state: null,
    status: '',
    tel: '',
    zipcode: '',
  };

  //지역별 배송비 정보
  public info_delivery: any = {};

  public emailAddressList: any = [
    'naver.com',
    'hanmail.net',
    'daum.net',
    'yahoo.co.kr',
    'gmail.com',
    'nate.com',
    'hotmail.com',
    'empal.com',
    'freechal.com',
    'paran.com',
    'koera.com',
    'dreamwiz.com'
  ];

  public total_mileage: any = 0; //총합 적립마일리지
  public total_productprice: any = 0; //총합 상품가격
  public total_sale: any = 0; //총합 할인가격
  public total_delivery: any = 0; //총합 배송비
  public total_estimate: any = 0; //총합 결제금액
  public each_delivery_amt: any = 0; // 탑박스 개별 배송비
  public kuryakyn_delivery_amt: any = 0; // 쿠리야킨 묶음 배송비

  public daumAddressOptions =  {
    class: ['btn', 'overlap-btn', 'h33', 'f12'],
    type: 'inline',
    target: 'daumAddressApiWrap'
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private activatedRoute : ActivatedRoute,
    private router: Router,
    private location: Location,
    private toastrService: ToastrService,
    private deviceService: DeviceDetectorService,
    private authService: AuthService,
    private sOrderService: SOrderService,
    private sMemberService: SMemberService,
    private sProductService: SProductService,
    private sCartService: SCartService,
    private sEventService: SEventService,
  ) {
    this.buildForm();
    this.loadScripts();

    this.isLogIn$ = this.authService.isLoggedIn;

    this.isLogIn$.subscribe( login => {
      this.isLogIn = login;
    });

    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();

    if( this.isDesktop === true )
      this.orderForm.get('device').setValue('desktop');
    else if( this.isTablet === true )
      this.orderForm.get('device').setValue('tablet');
    else if( this.isMobile === true )
      this.orderForm.get('device').setValue('mobile');
    else
      this.orderForm.get('device').setValue('etc');
  }

  /*******************************************************************************
    설  명: 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.orderForm = this.formBuilder.group({
      // 주문자 정보
      info_order_name: [ '', [Validators.required] ],
      info_order_hp1: [ '', [Validators.required, Validators.pattern('^\d{0,1}[0-9]{2,4}$')] ],
      info_order_hp2: [ '', [Validators.required, Validators.pattern('^\d{0,1}[0-9]{3,4}$')] ],
      info_order_hp3: [ '', [Validators.required, Validators.pattern('^\d{0,1}[0-9]{4}$')] ],
      info_order_email: [ '', [Validators.required, Validators.pattern('^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$')] ],
      info_order_email1:[ '', [Validators.required]],
      info_order_email2:[ '', [Validators.required]],

      // 배송지 정보
      info_receive_name: [ '', [Validators.required] ],
      info_receive_phone1: [ '', [Validators.required, Validators.pattern('^\d{0,1}[0-9]{2,4}$')] ],
      info_receive_phone2: [ '', [Validators.required, Validators.pattern('^\d{0,1}[0-9]{3,4}$')] ],
      info_receive_phone3: [ '', [Validators.required, Validators.pattern('^\d{0,1}[0-9]{4}$')] ],
      info_receive_address_zipcode: [ '', [Validators.required, Validators.pattern('^[0-9]{5}$')] ],
      info_receive_address_main: [ '', [Validators.required] ],
      info_receive_address_sub: [ '', [] ],
      info_receive_comment: [ '', [Validators.maxLength(100)] ],

      // 상품 정보
      info_product: [[], []],
      product: [[], []],
      delivery_charge: [[], []],

      order_qty: [0],
      order_amt: [0],
      discount_amt: [0],
      delivery_price: [0],
      in_mileage: [0],
      use_mileage: [0, [Validators.pattern('^[0-9]{1,}')]], //사용할 마일리지
      coupon_code: ['', []],
      coupon_type: ['', []],
      coupon_seq: ['', []],
      bamacoupon_seq: ['0', []],
      membercoupon_seq: ['0', []],

      
      server_name: ['', []],
      mid: ['', []],
      oid: ['', []],
      price: ['', []],
      memNo: ['', []],
      isLogIn: ['', []],
      tmpSeq: ['', []],

      event_seq: ['', []],
      goodname: ['', []],
      account_seq: ['', []],
      bank: ['', []],
      bank_account: ['', []],
      receipt_name: ['', []],

      isEditMember: ['', []],
      isPushAddress: ['', []],
      isDefaultAdress: ['', []],

      device: ['', []],
    });

    this.orderForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.orderForm, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    const path = this.location.path();
    const url = window.location.href;
    this.serverName = url.replace(path, '');

    // 주문상품 정보 가져오기
    this.getInfoProduct();
  }

  /*******************************************************************************
    설  명 : 전화번호 분리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  splitPhoneNumber(phone: any, index: number) {
    if( phone === null || ( phone !== null && phone.indexOf('-') < 0 ) ) return phone;

    if (index < 0) {
      return phone.split('-'); 
    };
    return phone.split('-')[index] || '';
  };

  /*******************************************************************************
    설  명 : KG이니시스페이 JS 모듈 로드
  *******************************************************************************/
  loadScripts() {
    const node = document.createElement('script');
    node.src = 'https://stdpay.inicis.com/stdjs/INIStdPay.js';
    // node.src = 'https://stgstdpay.inicis.com/stdjs/INIStdPay.js';
    node.type = 'text/javascript';
    node.async = false;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  /*******************************************************************************
    설  명 : 할인쿠폰 적용
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCouponCode() {
    // 배민 쿠폰 적용 시 수량이 1개 초과면 쿠폰이 적용 되지 않도록 한다. 
    if (this.orderForm.controls.order_qty.value > 1)
    {
      this.toastrService.error( '배민 쿠폰은 1개만 구매 할 수 있습니다. ', '');
       return;
    }
    if( this.orderForm.controls.coupon_code.value == '' ) {
      this.toastrService.info( '쿠폰코드를 입력하시기 바랍니다.', '');
                  // 쿠폰코드 적용 후 주문상품 정보 가져오기
                  this.getInfoProduct();
    } else {
      this.sEventService.getEventCouponCheck( this.orderForm.controls.coupon_code.value ).then(response => {
        if(response.ResultCode) {
          if( response.data?.seq === undefined ) {
            this.toastrService.info( '쿠폰코드를 다시 확인하시기 바랍니다. 이미 사용되었거나 잘못된 쿠폰일 수 있습니다.' , '');
                        // 쿠폰코드 적용 후 주문상품 정보 가져오기
                        this.getInfoProduct();
          } else {
            this.toastrService.success( '쿠폰이 성공적으로 적용되었습니다. ( 입력하신 쿠폰코드로 할인이 적용되는 상품이 있을 때만 자동으로 할인이 적용됩니다. )', '');
            this.orderForm.patchValue({
              coupon_type: response.data.coupon_type,
              coupon_seq: response.data.coupon_seq,

            })

          /*
          const couponInputElement = document.querySelector('input[formControlName="coupon_code"]') as HTMLInputElement;
          
          if (couponInputElement) {
            // HTML 속성 추가하여 완전 비활성화
            couponInputElement.setAttribute('readonly', 'true');
            couponInputElement.classList.add('disabled'); // 선택적으로 스타일을 변경해 비활성화 표현
            couponInputElement.style.pointerEvents = 'none'; // 클릭을 무시하게 함

            // 값이 변경되지 않도록 입력 이벤트 제거
            couponInputElement.addEventListener('input', (event) => {
              event.preventDefault();
              couponInputElement.value = this.orderForm.controls.coupon_code.value; // 기존 값으로 되돌림
            });
          }

*/
          console.log(this.orderForm);

            // 총 합계 초기화
            this.total_productprice = 0;
            this.total_mileage = 0;
            this.total_sale = 0;
            this.total_estimate = 0;

            // 쿠폰코드 적용 후 주문상품 정보 가져오기
            this.getInfoProduct();
          // 쿠폰 코드 입력란 비활성화
          this.orderForm.controls.coupon_code.disable();


          }
        } else {
          this.toastrService.error( response.ResultMessage, '');
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 쿠폰코드 엔터키 입력 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  pressKeyCouponCode(event: any) {
    if( event.key == 'Enter' ) {
      this.setCouponCode();
    }
  }
  
  /*******************************************************************************
    설  명 : 할인쿠폰 선택창 띄우기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openCouponSelect() {
    const modalRef = this.modalService.open(ProductCouponComponent, options);
    modalRef.componentInstance.info_product = this.info_product;
    modalRef.componentInstance.info_coupon_list = this.info_coupon_list;
    modalRef.componentInstance.total_productprice = this.total_productprice;
    modalRef.componentInstance.total_sale = this.total_sale;

    modalRef.result.then(result => {
      this.setTotal();
      if(this.total_estimate < 0) {this.useMileage('all');}
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 무이자할부안내
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  installmentGuide() {
    const modalRef = this.modalService.open(InstallmentGuideComponent, options1);

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 주문자 정보 및 기본배송지 정보 가져오기
  *******************************************************************************/
  getMemberAddressInfo( check: boolean ) {
    this.sMemberService.getMemberAddressInfo().then(response => {
      if(response.ResultCode) {
        this.memberInfo = response.data;

        // email @ 분리
        let tmp = response.data.email?.split('@');
        let tmp1 = tmp !== undefined ? tmp[0] : '';
        let tmp2 = tmp !== undefined ? tmp[1] : '';
        this.emailAddress = tmp2;
        this.emailReadonly = ( this.emailAddressList.indexOf(tmp2) > -1 ) ? true : false;

        // hp 분리
        let hp = response.data.hp;
        let hp1 = this.splitPhoneNumber(hp, 0);
        let hp2 = this.splitPhoneNumber(hp, 1);
        let hp3 = this.splitPhoneNumber(hp, 2);

        //hp '-' 있을때
        if ( hp2 === undefined ) {
          hp1 = response.data.hp.substr(0, 3);
          hp2 = response.data.hp.substr(3, 4);
          hp3 = response.data.hp.substr(7, 4);
        }

        if( check === true ) {
          this.orderForm.patchValue({
            info_order_name : response.data.name,
            info_order_hp1 : hp1,
            info_order_hp2 : hp2,
            info_order_hp3 : hp3,
            info_order_email : response.data.email,
            info_order_email1 : tmp1,
            info_order_email2 : tmp2,
            info_receive_name : ( response.data.recipient_name ) ? response.data.recipient_name : response.data.name,
            info_receive_phone1 : hp1,
            info_receive_phone2 : hp2,
            info_receive_phone3 : hp3,
            info_receive_address_zipcode : response.data.zipcode,
            info_receive_address_main : response.data.address,
            info_receive_address_sub : response.data.address_detail
          });
        }

        // 협력점/프랜차이즈는 무통장을 기본으로
        if( this.memberInfo.grade == '0004' || this.memberInfo.grade == '0007' ) {
          this.setGopaymethod('NBank');
        }
        
        if (this.info_product[0].is_voucher == '1') {
          this.setGopaymethod('NBank');  
        }

        this.setTotalDelivery();
        this.setTotal();

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 배송료 가져오기
  *******************************************************************************/
  getConfigDelivery() {
    this.sProductService.getConfigDelivery().then( response => {
      if( response.ResultCode ) {
        this.info_delivery = response.data;

        this.setTotalDelivery();
        this.setTotal();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    })
  }

  /*******************************************************************************
    설  명 : 마일리지 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getSumRemainedMileage() {
    this.sMemberService.getSumRemainedMileage().then( response => {
      if ( response.ResultCode ) {
        this.info_mileage = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 배송비 세팅
  *******************************************************************************/
  setTotalDelivery() {
    this.total_delivery = 0;
    this.each_delivery_amt = 0;

    // 기본 배송비 부과
    if( this.memberInfo.grade == '0004' || this.memberInfo.grade == '0007' ) {
      this.total_delivery += parseInt(this.info_delivery.delivery_fra_amt);
    } else {
      this.total_delivery += parseInt(this.info_delivery.delivery_amt);

    }

    // 무료배송 기준금액 이상일 때
    if( this.total_productprice >= this.info_delivery.delivery_free_condition ) {
      this.total_delivery = 0;
    }
  
    // 협력점/프랜차이즈만 추가 배송비 부여
    if( this.memberInfo.grade == '0004' || this.memberInfo.grade == '0007' ) {
  
      // 배송료 부과하는 업체 리스트 구하기
      let deliveryChargeList: any = this.info_product.filter((obj: any) => 
          obj.product_gbn == '2000'
        ).map((obj: any) => {
          return {
            customer_seq: obj.customer_seq,
            customer_name: obj.customer_name,
            delivery_price: ( 
              // [송미옥] [오전 11:45] 프랜차이즈 배송비 산정 부분 수정 부탁드립니다. 
              // 업체명(매입처) : 바이크랩_쇼에이 매장 주문건의 경우, 기존 건별 3,000원 부과에서 무료배송으로 적용(세나와 동일하게)
              this.memberInfo.grade == '0007' && 
              obj.customer_seq == '358' &&
              this.memberInfo.zipcode == this.orderForm.controls.info_receive_address_zipcode.value &&
              this.memberInfo.address == this.orderForm.controls.info_receive_address_main.value
            ) ? 0 : this.info_delivery.delivery_fra_amt
          }
        }
      );

      // 중복되는 업체 제거
      this.orderForm.patchValue({
        delivery_charge: 
          deliveryChargeList.filter((character: any, idx: number, arr: any) => {
            return arr.findIndex((item: any) => item.customer_seq === character.customer_seq) === idx
          })
      });

      let delivery_charge: any = this.orderForm.controls.delivery_charge.value;

      // 주문상품 업체별 배송료 부여
      if( delivery_charge.length > 0 ) {
        if( this.total_productprice < this.info_delivery.delivery_free_condition ) {
          this.total_delivery = 0;
        }

        delivery_charge.forEach((item: any) => {
          this.total_delivery += parseInt(item.delivery_price);
        });
      }

      let ownProductList: any = [];
      ownProductList = this.info_product.filter((obj: any) => obj.product_gbn == '1000');

      // 기본주소가 달라졌을 때 배송비 부여
      if( ownProductList.length > 0 ) {
        if( this.total_productprice >= this.info_delivery.delivery_free_condition ) {
          if( 
            this.memberInfo.zipcode !== this.orderForm.controls.info_receive_address_zipcode.value ||
            this.memberInfo.address !== this.orderForm.controls.info_receive_address_main.value
          ) {
            this.total_delivery += parseInt(this.info_delivery.delivery_fra_amt);
          }
        }
      }

    }

    // 배송지가 제주도일 때
    let infoReceiveAddressZipcode = this.orderForm.get('info_receive_address_zipcode').value;
    if( infoReceiveAddressZipcode && ( infoReceiveAddressZipcode.substr(0,2) == '63' || infoReceiveAddressZipcode.substr(0,2) == '69' ) ) {
      this.total_delivery += parseInt(this.info_delivery.delivery_overcharge);
    }

    // 탑박스 개별 배송비 체크
    this.info_product.map( rows => {
      if ( rows.each_delivery_price == '1' ) {
        this.each_delivery_amt += rows.sum_qty * this.info_delivery.delivery_amt;
      } else {
        return;
      }
    });

    if( this.each_delivery_amt > 0 ) this.total_delivery = 0;

    if( this.each_delivery_amt > 0 && this.total_productprice >= this.info_delivery.delivery_free_condition ) {
      this.each_delivery_amt -= this.info_delivery.delivery_amt;
    }
  
    // 요일(일:0 ~ 토:6)
    let day_num: any = moment().format('e');

    console.log(`배송비 확인 : ${this.orderForm.controls.coupon_type.value}`); 

    // 쿠리야킨 브랜드 배송비 체크
    this.info_product.forEach( row => {
      if( row.children.length > 0 ) {
        row.children.forEach( item => {
          if( item.brand_name == '쿠리야킨' || item.brand_ename == 'kuryakyn' ) {
            // 월~목 사이에만 배송비 부여
            if( day_num > 0 && day_num < 5 ) {
              this.kuryakyn_delivery_amt = this.info_delivery.delivery_kuryakyn;
            }
          }
        });
      }
    });

    this.total_delivery = parseInt(this.total_delivery) + parseInt(this.each_delivery_amt) + parseInt(this.kuryakyn_delivery_amt);
    

    // 배민쿠폰으로 구매하는 경우는 배송비를 제외한다. 
    if (this.orderForm.controls.coupon_type.value == '4000') {
      this.total_delivery = 0;
    }
    this.orderForm.patchValue({
      delivery_price: this.total_delivery
    });

 

    this.setTotal();
  }

  /*******************************************************************************
    설  명 : 주문상품 정보 가져오기
  *******************************************************************************/
  getInfoProduct() {
    let orderList = JSON.parse( sessionStorage.getItem('bikemart_order') );

    if( !orderList || orderList === null ) {
      this.toastrService.error('주문상품 정보를 찾을 수 없습니다.', '주문/결제');
      history.back();
      return false;
    }

    // 세션 스토리지의 주문 정보를 다시 한번 API를 통해 검증
    let cartList: any = [];
    orderList.forEach((obj: any) => {
      let item: any = {
        product_seq: '',
        productList: [],
        giftList: []
      };
  
      item.product_seq = obj.product_seq;

      obj.children.forEach((row: any) => {
        item.productList.push({
          product_seq: row.product_seq,
          product_property_seq: row.product_property_seq,
          qty: row.qty,
          discount_amt: row.discount_amt,
          discount_rate: row.discount_rate,
          event_seq: row.event_seq,
          isGift: row.isGift,
          product_freegift_seq: row.product_freegift_seq,
          freegift_max_count: row.freegift_max_count,
          is_voucher:row.is_voucher,
        });
      });

      cartList.push(item);
    });
    console.log(`회원쿠폰번호 : ${this.orderForm.controls.membercoupon_seq.value}`);
    this.sCartService.getCartProductInfoList( JSON.stringify(cartList), this.orderForm.controls.coupon_code.value ,this.orderForm.controls.membercoupon_seq.value ).then( response => {
      if( response.ResultCode ) {

        // 다시 가져온 데이터를 세션스토리지에 저장
        sessionStorage.setItem('bikemart_order', JSON.stringify(response.data));

        // 합계를 구하기 전에 변수를 초기화 한다. 
        this.total_productprice = 0 ;
        this.total_mileage = 0 ; 
        this.total_sale = 0 ;

        this.info_product = response.data;
        console.log(`상품정보: ${JSON.stringify(response.data)}`);

        this.info_product.forEach((item: any) => {
          this.total_mileage += item.sum_mileage;
          this.total_productprice += item.sum_total_amt;
    
          // 이벤트 상품이 존재하는지 체크
          if( item.children.length > 0 ) {
            item.children.forEach((row: any) => {
              if( row.event_type == '3000' ) this.isCoupon = true;
            });
          }
        });
    
        this.orderForm.patchValue({
          product: this.info_product,
          goodname : this.info_product[0].product_name + ( this.info_product.length > 1 ? '외 ' + (this.info_product.length - 2) + '건' : '' ),
          in_mileage: this.total_mileage
        });
       
        // 무통장입금 은행 리스트 가져오기
        this.getBankAccountList();

       // 바마쿠폰 리스트 가져오기
        this.getBamaCouponList(cartList);

        // 배송료 가져오기
        this.getConfigDelivery();

        // 마일리지 정보 가져오기
        this.getSumRemainedMileage();
    
        // 주문자 정보 및 기본배송지 정보 가져오기
        if( this.isLogIn === true ) this.getMemberAddressInfo(true);

      } else {
        this.toastrService.error(response.ResultMessage, '');
      }
    });
  }
  /*******************************************************************************
    설  명 : 배송지 선택
  *******************************************************************************/
  getInfoReceive( check: boolean ) {
    if( check === true ) {
      this.orderForm.patchValue({
        info_receive_name : '',
        info_receive_phone1 : '',
        info_receive_phone2 : '',
        info_receive_phone3 : '',
        info_receive_address_zipcode : '',
        info_receive_address_main : '',
        info_receive_address_sub : ''
      });
    } else {
      let name: any = ( this.memberInfo.recipient_name !== null ) ? this.memberInfo.recipient_name : this.memberInfo.name;
      let hp: any = ( this.memberInfo.recipient_hp !== null ) ? this.memberInfo.recipient_hp : this.memberInfo.hp;
      
      let hp1: any = ( hp !== null ) ? this.splitPhoneNumber(hp, 0) : '';
      let hp2: any = ( hp !== null ) ? this.splitPhoneNumber(hp, 1) : '';
      let hp3: any = ( hp !== null ) ? this.splitPhoneNumber(hp, 2) : '';

      this.orderForm.patchValue({
        info_receive_name : name,
        info_receive_phone1 : hp1,
        info_receive_phone2 : hp2,
        info_receive_phone3 : hp3,
        info_receive_address_zipcode : this.memberInfo.zipcode,
        info_receive_address_main : this.memberInfo.address,
        info_receive_address_sub : this.memberInfo.address_detail
      });
    }

    this.setTotalDelivery();
  }

  /*******************************************************************************
    설  명 : 주문자 정보 동일
  *******************************************************************************/
  setInfoReceive() {
    this.orderForm.patchValue({
      info_receive_name : this.orderForm.controls.info_order_name.value,
      info_receive_phone1 : this.orderForm.controls.info_order_hp1.value,
      info_receive_phone2 : this.orderForm.controls.info_order_hp2.value,
      info_receive_phone3 : this.orderForm.controls.info_order_hp3.value,
      info_receive_address_zipcode : ( this.memberInfo.zipcode ) ? this.memberInfo.zipcode : '',
      info_receive_address_main : ( this.memberInfo.address ) ? this.memberInfo.address : '',
      info_receive_address_sub : ( this.memberInfo.address_detail ) ? this.memberInfo.address_detail : ''
    });
  }

  /*******************************************************************************
    설  명 : 주문자명과 동일
  *******************************************************************************/
  setInfoReceipt() {
    this.orderForm.patchValue({
      receipt_name : this.orderForm.controls.info_order_name.value
    });
  }
  
  /*******************************************************************************
    설  명 : 쿠폰 정보 가져오기
  *******************************************************************************/
  getInfoCouponList() {
    this.info_coupon_list.push({
      name: '오투오즈 단독 100% 할인 쿠폰',
      code : 'A123Z',
      method: 1, //0: 액면가, 1: 비율
      sale: 100 //할인량
    });
    this.info_coupon_list.push({
      name: '오투오즈 단독 100만원 할인 쿠폰',
      code : 'B123Z',
      method: 0, //0: 액면가, 1: 비율
      sale: 1000000 //할인량
    });
    this.info_coupon_list.push({
      name: '출석체크 1만원 할인 쿠폰',
      code : 'C337P',
      method: 0, //0: 액면가, 1: 비율
      sale: 10000 //할인량
    });
  }

  /*******************************************************************************
    설  명 : 마일리지 모두사용
  *******************************************************************************/
  useMileage( value: any ) {
    let mileage = parseInt(value);

    if( isNaN(mileage) ) {
      mileage = 0;
    }

    if( value == 'all' ) {
      mileage = this.info_mileage;
    }

    let maximum_m = this.total_productprice + this.total_delivery;

    if( mileage > this.info_mileage ) {
      mileage = this.info_mileage;
    } else if( mileage > maximum_m ) {
      mileage = maximum_m;
    } else if( mileage < 0 ) {
      mileage = 0;
    }

    if( mileage == maximum_m ) {
      this.gopaymethod = 'NBank';
    } else {
      this.gopaymethod = 'Card';
    }

    this.orderForm.patchValue({
      use_mileage : mileage
    });

    this.setTotal();
  }

  /*******************************************************************************
    설  명 : 결제 관련 수치 계산
  *******************************************************************************/
  setTotal() {
    let qty: any = 0;
    this.info_product.forEach((element, index) => {
      qty += parseInt(element.sum_qty);
    });

    this.total_estimate = this.total_productprice - this.orderForm.get('use_mileage').value;

    this.orderForm.patchValue({
      order_qty: qty,
      order_amt: this.total_estimate
    });
  }

  /*******************************************************************************
    설  명 : 다음 우편번호찾기 API
  *******************************************************************************/
  setDaumAddressApi(data) {
    if( data === false ) {
      this.toastrService.warning('카카오 주소검색 서비스장애로 직접 입력하시기 바랍니다.', '');
      this.infoReceiveAddressZipcode.nativeElement.focus();
    } else {
      this.orderForm.patchValue({
        info_receive_address_zipcode : data.zip,
        info_receive_address_main : data.addr
      });

      this.infoReceiveAddressSub.nativeElement.focus();
    }
  }

  /*******************************************************************************
    설  명 : 결제하기
    (쿠폰, 마일리지 등의 사용자 선택까지 끝났을 때 KG 이니시스 Form 작성을 위한 데이터를 먼저 받아오고 결제 실행)
  *******************************************************************************/
  submit() {
    this.orderForm.patchValue({
      info_order_email : this.orderForm.controls.info_order_email1.value + '@' + this.orderForm.controls.info_order_email2.value,
      info_order_hp : this.orderForm.controls.info_order_hp1.value + '-' + this.orderForm.controls.info_order_hp2.value + '-' + this.orderForm.controls.info_order_hp3.value,
      info_receive_phone : this.orderForm.controls.info_receive_phone1.value + '-' + this.orderForm.controls.info_receive_phone2.value + '-' + this.orderForm.controls.info_receive_phone3.value,
    });

    // 전체 마일리지 결제 시 은행과 입금자명 필수체크 제외
    if( this.gopaymethod == 'NBank' && ( this.orderForm.controls.use_mileage.value == (this.total_productprice + this.total_delivery) ) ) {
      this.orderForm.controls.account_seq.setValidators([]);
      this.orderForm.controls.account_seq.updateValueAndValidity();
      this.orderForm.controls.receipt_name.setValidators([]);
      this.orderForm.controls.receipt_name.updateValueAndValidity();      
    }

    this.utilService.makeFormDirtyAndUpdateErrors(this.orderForm, this.formErrors);

    if( !this.orderForm.value.product.length ) {
      this.toastrService.warning('비정상적인 접근입니다.', '상품없음');
      return;
    }

    if( this.orderForm.valid ) {
      // 무통장입금
      if ( this.gopaymethod == 'NBank' ) {

        this.isSubmit = true;

        let product: any = [];
        product = this.orderForm.controls.product.value;

        // 다중클릭 방지를 위해 다른 폼컨트롤로 이동 (product -> info_product)
        this.orderForm.get('info_product').setValue(JSON.stringify(product));

        // 폼데이터만 따로 가져오기
        let formData: any = JSON.parse(JSON.stringify(this.orderForm.value));

        // 폼데이터에서 기존 상품정보 product 제거
        formData.product = [];

        this.sOrderService.setOrderSave( formData ).then( response => {
          if( response.ResultCode ) {
            // this.toastrService.success( '주문 정보를 성공적으로 저장하였습니다.', '');

            this.router.navigate(
              [ '/order/complete' ],
              {
                relativeTo: this.activatedRoute,
                queryParams: { orderSeq: response.orderSeq, memNo: response.memNo, tmpSeq: this.IniPayStd.tmpSeq },
                queryParamsHandling: ''
              }
            );
          } else {
            this.isSubmit = false;

            this.toastrService.error( response.ResultMessage, '' );
          }
        });

      // PG 결제
      } else {
        this.sOrderService.getFormValue( this.orderForm ).then( response => {
          if( response.ResultCode ) {
            this.IniPayStd = response.data;

            if( this.IniPayStd.price == '0' ) {
              this.toastrService.warning( '0원 결제 시 무통장입금을 선택하시기 바랍니다.', '');
              return false;
            }

            if( this.isDesktop === true ) {
              let names: any = ['mid', 'oid', 'price', 'timestamp', 'signature', 'mKey', 'closeUrl', 'merchantData'];
              for(let i = 0; i < names.length; i++) {
                const element = document.getElementById(`INI${names[i]}`);
                if( element !== null ) element.remove();

                const input = document.createElement("input");
                input.setAttribute('type', 'hidden')
                input.setAttribute('name', names[i]);
                input.setAttribute('id', `INI${names[i]}`);

                let value: any = '';
                if( names[i] == 'closeUrl' ) 
                  value = `${this.serverName}/order/inipay-close?tmpSeq=${this.IniPayStd.tmpSeq}`;
                else if( names[i] == 'merchantData' )
                  value = `memNo=${this.IniPayStd.memNo}&isLogIn=${this.isLogIn}&tmpSeq=${this.IniPayStd.tmpSeq}`;
                else
                  value = this.IniPayStd[ names[i] ];

                input.setAttribute('value', value);

                const INIPayForm = document.getElementById("INIPayForm");
                INIPayForm.appendChild(input);
              }

              INIStdPay.pay('INIPayForm');
            } else {
              if( this.isMobile === true || this.isTablet === true ) {
                this.orderForm.patchValue({
                  server_name: this.serverName,
                  mid: this.IniPayStd.mid,
                  oid: this.IniPayStd.oid,
                  price: this.IniPayStd.price,
                  memNo: this.IniPayStd.memNo,
                  tmpSeq: this.IniPayStd.tmpSeq,
                  isLogIn: this.isLogIn
                });

                this.kginicis.nativeElement.action = this.serverName + "/api/shop/kginicis/INISmartPay/inipay_mobile_web_request.php";
                this.kginicis.nativeElement.target = "_self";
                this.kginicis.nativeElement.submit();
              }
            }
          } else {
            this.toastrService.error( response.ResultMessage, '' );
          }
        });
      }
    } else {
      this.utilService.showToast(this.orderForm, this.formErrors);
    }
  }

  /*******************************************************************************
    설  명 : 배송지관리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openDestination() {
    const modalRef = this.modalService.open(MypageDestinationComponent, options2);

    modalRef.result.then((result) => {
      if( result !== null ) {
        this.getMemberAddressInfo( false );

        this.orderForm.patchValue({
          info_receive_name : result.recipient_name,
          info_receive_phone1 : this.splitPhoneNumber(result.recipient_hp, 0),
          info_receive_phone2 : this.splitPhoneNumber(result.recipient_hp, 1),
          info_receive_phone3 : this.splitPhoneNumber(result.recipient_hp, 2),
          info_receive_address_zipcode : result.zipcode,
          info_receive_address_main : result.address,
          info_receive_address_sub : result.address_detail
        });
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 무통장입금 은행 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBankAccountList() {
    this.sOrderService.getBankAccountList().then( response => {
      if ( response.ResultCode ) {
        this.accountList = response.data;
      } else {
        this.accountList = [];
      }
    });
  }
 
  /*******************************************************************************
    설  명 : 고객이 보유한 바마 쿠폰 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    getBamaCouponList(data:any) {

    
      this.sOrderService.getBamaCouponList(JSON.stringify(data)).then( response => {
        if ( response.ResultCode ) {

          console.log(`쿠폰리스트: ${JSON.stringify(response.data)}`);
          this.couponList = response.data;

        } else {
          this.couponList = [];
        }
      });
    }

  /*******************************************************************************
    설  명 : 바마 쿠폰을 선택 했을 시 이벤트 
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    selectedCoupon(event: any) {
      if ( event.target.value == "" ) {
        this.orderForm.patchValue({
          membercoupon_seq: "",
        })
      } else {
        let selected = this.couponList.filter(item => item.seq === event.target.value);
  
        this.orderForm.patchValue({
          membercoupon_seq: event.target.value,
        })

        // 바마쿠폰  적용 후 주문상품 정보 가져오기
        this.getInfoProduct();
        
      }
    }    
  

  /*******************************************************************************
    설  명 : 무통장입금 은행 선택 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectedAccount(event: any) {
    if ( event.target.value == "" ) {
      this.orderForm.patchValue({
        account_seq: "",
        bank: "",
        // receipt_name: ""
      })
    } else {
      let selected = this.accountList.filter(item => item.seq === event.target.value);

      this.orderForm.patchValue({
        account_seq: event.target.value,
        bank: selected[0].bank_name,
        // receipt_name: this.orderForm.controls.info_order_name.value,
        bank_account: selected[0].bank_account
      })
    }
  }

  /*******************************************************************************
    설  명 : 이메일 주소 선택시 readonly 및 직접입력칸에 이메일 주소 보내기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public selectEmail($event) {
    if ( $event.target.value === '' ) {
      this.emailReadonly = false;
    } else {
      this.emailReadonly = true;
      this.emailAddress = $event.target.value;
    }
    this.orderForm.patchValue({
      info_order_email2: $event.target.value
    });
  }

  /*******************************************************************************
    설  명 : 연락처 입력 시 자동 이동
  *******************************************************************************/
  hpKeyUp( event: any ) {
    let id: any = event.target.id;
    let value: any = event.target.value;

    if( id == 'info_order_hp1' && value == '010' || value.length == '4' ) {
      this.infoOrderHp2.nativeElement.focus();
    }

    if( id == 'info_receive_phone1' && value == '010' || value.length == '4' ) {
      this.infoReceivePhone2.nativeElement.focus();
    }

    if( id == 'info_order_hp2' && value.length == '4' ) {
      this.infoOrderHp3.nativeElement.focus();
    }

    if( id == 'info_receive_phone2' && value.length == '4' ) {
      this.infoReceivePhone3.nativeElement.focus();
    }
  }

  /*******************************************************************************
    설  명 : 결제방법 선택 시
  *******************************************************************************/
  setGopaymethod(value: any) {
    this.gopaymethod = value;

    if( this.gopaymethod == 'NBank' ) {
      this.orderForm.controls.account_seq.setValidators([Validators.required]);
      this.orderForm.controls.account_seq.updateValueAndValidity();
      this.orderForm.controls.receipt_name.setValidators([Validators.required]);
      this.orderForm.controls.receipt_name.updateValueAndValidity();
    } else {
      this.orderForm.controls.account_seq.setValidators([]);
      this.orderForm.controls.account_seq.updateValueAndValidity();
      this.orderForm.controls.receipt_name.setValidators([]);
      this.orderForm.controls.receipt_name.updateValueAndValidity();
    }
  }
  
}
