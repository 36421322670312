import { Component, AfterViewInit } from '@angular/core';
declare const Kakao: any;

@Component({
  selector: 'app-kakao-chat',
  templateUrl: './kakao-chat.component.html',
  styleUrls: ['./kakao-chat.component.scss'],
})
export class KakaoChatComponent implements AfterViewInit {
  private readonly kakaoAppKey = '7194fc0a6697ef357a530524f4c87e3f'; // 발급받은 JavaScript 앱 키
  private readonly kakaoChannelId = '_lxotyj'; // 채널 ID

  constructor() {}

  ngAfterViewInit(): void {
    // Kakao SDK 초기화
    if (typeof Kakao !== 'undefined' && !Kakao.isInitialized()) {
      Kakao.init(this.kakaoAppKey);
    }

    // 채팅 버튼 생성
    Kakao.Channel.createChatButton({
      container: '#kakao-chat-button', // 버튼을 삽입할 HTML 요소 ID
      channelPublicId: this.kakaoChannelId, // 카카오톡 채널 ID
      size: 'large', // 버튼 크기
    });
  }
}
